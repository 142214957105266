<template>
	<form @submit.prevent="handleSubmit(!v$.$invalid)" class="p-grid">
		<Toast />
		<ConfirmDialog></ConfirmDialog>
		
		<div class="grid p-fluid stickBar">
			<div class="col-12">
				<div class="card card-w-title" id="stickBar">
					<Menubar :model="nestedMenuitems">
						<template #end>
							<div class="flex justify-content-end flex-wrap card-container green-container">
								<div class="flex align-items-center justify-content-center font-bold text-gray-500 text-xl m-2">
									OTOMASYON PROJESİ
								</div>
								<div class="flex align-items-center justify-content-center m-2">
									<Button class="p-button-rounded p-button-danger mr-0 mb-0" v-if="$router.options.history.state.back != null" icon="pi pi-times" @click="$router.go(-1)"/>
								</div>
							</div>
						</template>
					</Menubar>
				</div>
			</div>
		</div>

		<div class="grid">
			<div class="col-12">
				<div class="card">
					<EntityHeader label="OTOMASYON PROJESİ" :entityName="ENTITY_NAME"></EntityHeader>
					<div class="grid">
						<div class="col-3">
							<div class="field p-fluid">
								<label for="projeAdi">Proje Adı <span style="color:red">*</span></label>
								<InputText id="projeAdi" type="text" v-model="mainData.bm_name" :disabled="isDisabled"/>
								<span v-if="v$.mainData.bm_name.$error && submitted">
									<span id="email-error" v-for="(error, index) of v$.mainData.bm_name.$errors" :key="index">
										<small class="p-error">{{error.$message}}</small>
									</span>
								</span>
							</div>
						</div>
						<div class="col-3">
							<div class="field p-fluid">
								<EntityLookup id="firma" v-model="mainData.bm_firmaId" ref="entity_firma" label="Firma" entityName="account" nameField="name" :state="true" :required="true"
								@itemSelected="firmaSelected = $event" @itemCleared="firmaSelected = null" ></EntityLookup>
								<span v-if="v$.mainData.bm_firmaId.$error && submitted">
									<span id="email-error" v-for="(error, index) of v$.mainData.bm_firmaId.$errors" :key="index">
										<small class="p-error">{{error.$message}}</small>
									</span>
								</span>
							</div>
						</div>
						<div class="col-3">
							<div class="field p-fluid">
								<EntityLookup id="firmayetkilisi" v-model="mainData.bm_firmayetkilisiId" ref="entity_firmayetkilisi"
									label="Firma Yetkilisi" entityName="contact" nameField="fullname" :state="true" :required="true"
									parentFieldName="parentcustomerid" :parentId="firmaSelected"
									@itemSelected="firmayetkilisiSelected = $event" @itemCleared="firmayetkilisiSelected = null">
								</EntityLookup>
								<span v-if="v$.mainData.bm_firmayetkilisiId.$error && submitted">
									<span id="email-error" v-for="(error, index) of v$.mainData.bm_firmayetkilisiId.$errors" :key="index">
										<small class="p-error">{{error.$message}}</small>
									</span>
								</span>
							</div>
						</div>
						<div class="col-3">
							<div class="field p-fluid">
								<EntityLookup id="projeTemsilcisi" v-model="mainData.bm_projetemsilcisiId" ref="entity_projetemsilcisi" label="Satış Temsilcisi" entityName="bm_teknikkullanicilar" nameField="bm_name" :state="true" :required="true"
								@itemSelected="projeTemsilcisiSelected = $event" @itemCleared="projeTemsilcisiSelected = null" ></EntityLookup>
								<span v-if="v$.mainData.bm_projetemsilcisiId.$error && submitted">
									<span id="email-error" v-for="(error, index) of v$.mainData.bm_projetemsilcisiId.$errors" :key="index">
										<small class="p-error">{{error.$message}}</small>
									</span>
								</span>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<div class="grid">
			<div class="col-12">
				<div class="card">
					<h5>TARİH ve İLERLEME BİLGİLERİ</h5>
					<div class="grid">
						<div class="col">
							<div class="field p-fluid">
								<label for="baslangicTarihi">Başlangıç Tarihi <span style="color:red">*</span></label>
								<Calendar showButtonBar id="baslangicTarihi" v-model="mainData.bm_baslangictarihi" autocomplete="off" :showIcon="true" />
								<span v-if="v$.mainData.bm_baslangictarihi.$error && submitted">
									<span id="email-error" v-for="(error, index) of v$.mainData.bm_baslangictarihi.$errors" :key="index">
										<small class="p-error">{{error.$message}}</small>
									</span>
								</span>
							</div>
						</div>
						<div class="col">
							<div class="field p-fluid">
								<label for="bitisTarihi">Bitiş Tarihi</label>
								<Calendar showButtonBar id="bitisTarihi" v-model="mainData.bm_bitistarihi" autocomplete="off" :showIcon="true" />
							</div>
						</div>
						<div class="col">
							<div class="field p-fluid">
								<label for="xGunOnceHatirlat">x Gün Önce Hatırlat</label>
								<Dropdown id="xGunOnceHatirlat" v-model="mainData.bm_xgunoncehatirlat" :options="SM_xGunOnceHatirlat" optionLabel="Value" optionValue="AttributeValue" placeholder="x Gün Önce Hatırlat" :showClear="true" />
							</div>
						</div>
						<div class="col">
							<div class="field p-fluid">
								<label for="bm_projedurumu">Proje Durumu <span style="color:red">*</span></label>
								<Dropdown id="bm_projedurumu" v-model="mainData.bm_projedurumu" :options="SM_bm_projedurumu" optionLabel="Value" optionValue="AttributeValue" placeholder="Proje Durumu" :showClear="true" />
								<span v-if="v$.mainData.bm_projedurumu.$error && submitted">
									<span id="email-error" v-for="(error, index) of v$.mainData.bm_projedurumu.$errors" :key="index">
										<small class="p-error">{{error.$message}}</small>
									</span>
								</span>
							</div>
						</div>
						<div class="col" v-if="false">
							<div class="field p-fluid">
								<label for="projeilerlemedurumu">Proje İlerleme Durumu</label>
								<Dropdown id="projeilerlemedurumu" v-model="mainData.bm_projeilerlemedurumu" :options="SM_bm_projeilerlemedurumu" optionLabel="Value" optionValue="AttributeValue" placeholder="Proje İlerleme Durumu" :showClear="true" />
							</div>
						</div>
						<div class="col">
							<div class="field p-fluid">
								<label for="adet">Adet <span style="color:red">*</span></label>
								<InputNumber id="adet" v-model="mainData.bm_adet" mode="decimal" locale="tr-TR" :minFractionDigits="0" :min="1" :showButtons="true"  />
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</form>
</template>

<script>
import CrmService from "../service/CrmService";
import { useVuelidate } from "@vuelidate/core";
import { helpers, required } from "@vuelidate/validators";

export default {
	setup: () => ({ v$: useVuelidate() }),
	data() {
		return {
			crmService: null,
			submitted: false,
			OBJECT_TYPE_CODE: 10045,
			ENTITY_NAME: 'bm_otomasyonprojesi',
			ENTITY_SUBJECT: 'Otomasyon Projesi',
			mainData: {},

			nestedMenuitems: [
				{
					label:'Kaydet',
					icon:'pi pi-fw pi-save',
					command: () => { this.OnSave(false); },
					visible: () => !this.isDisabled
				},
				{
					label:'Kaydet & Kapat',
					icon:'pi pi-fw pi-save',
					command: () => { this.OnSave(true); },
					visible: () => !this.isDisabled
				},
			],
		}
	},
	async created() {
		this.crmService = new CrmService();
		console.log('created');

		window.addEventListener('scroll', this.handleScroll);

		this.mainData["bm_projedurumu"] = 0;
		this.mainData["bm_adet"] = 1;
    },
	computed: {
		entityId() {
			debugger;
			return this.$route.params.id;
		},
		profileData(){
			return this.$store.getters.getProfile;
		},
		isDisabled: function() {
			if (this.profileData) {
				if (this.profileData.moduller) {
					const filtered = this.profileData.moduller.filter(x => x.name == this.ENTITY_SUBJECT);
					if (filtered.length > 0) {
						return filtered[0].isWrite == false;
					}
				}
			}

			return true;
		},
		isDeleted: function() {
			if (this.profileData) {
				if (this.profileData.moduller) {
					const filtered = this.profileData.moduller.filter(x => x.name == this.ENTITY_SUBJECT);
					if (filtered.length > 0) {
						return filtered[0].isDelete == true;
					}
				}
			}

			return false;
		},
		SM_xGunOnceHatirlat: function () {
			return this.$store.getters.getStringMapByEntityId(this.OBJECT_TYPE_CODE, 'bm_xgunoncehatirlat');
		},
		SM_bm_projedurumu: function () {
			return this.$store.getters.getStringMapByEntityId(this.OBJECT_TYPE_CODE, 'bm_projedurumu');
		},
		SM_bm_projeilerlemedurumu: function () {
			return this.$store.getters.getStringMapByEntityId(this.OBJECT_TYPE_CODE, 'bm_projeilerlemedurumu');
		},
		firmaSelected: {
			get: function () {
				debugger;
				if (this.mainData["bm_firmaId"]) {
					return { "Value": this.mainData["bm_firmaId"], "Name": this.mainData["bm_firmaName"] }
				} else{ return null; }
			},
			set: function(newValue) {
				debugger;
				console.log(newValue);

				if (newValue == null) {
					this.mainData["bm_firmaId"] = null;
					this.mainData["bm_firmaName"] = null;
				}
				else {
					this.mainData["bm_firmaId"] = newValue.Value;
					this.mainData["bm_firmaName"] = newValue.Name;
				}
			}
		},
		firmayetkilisiSelected: {
			get: function () {
				if (this.mainData["bm_firmayetkilisiId"]) {
					return { "Value": this.mainData["bm_firmayetkilisiId"], "Name": this.mainData["bm_firmayetkilisiName"] }
				} else{ return null; }
			},
			set: function(newValue) {
				if (newValue == null) {
					this.mainData["bm_firmayetkilisiId"] = null;
					this.mainData["bm_firmayetkilisiName"] = null;
				}
				else {
					this.mainData["bm_firmayetkilisiId"] = newValue.Value;
					this.mainData["bm_firmayetkilisiName"] = newValue.Name;
				}
			}
		},
		projeTemsilcisiSelected: {
			get: function () {
				if (this.mainData["bm_projetemsilcisiId"]) {
					return { "Value": this.mainData["bm_projetemsilcisiId"], "Name": this.mainData["bm_projetemsilcisiName"] }
				} else { return null; }
			},
			set: function (newValue) {
				if (newValue == null) {
					this.mainData["bm_projetemsilcisiId"] = null;
					this.mainData["bm_projetemsilcisiName"] = null;
				}
				else {
					this.mainData["bm_projetemsilcisiId"] = newValue.Value;
					this.mainData["bm_projetemsilcisiName"] = newValue.Name;
				}
			}
		},
	},
	methods: {
		async OnSave(kapat) {
			debugger;
			
			this.submitted = true;
			this.v$.$touch();

			if (this.v$.$invalid) {
				this.v$.$errors.forEach(element => {
					this.$toast.add({ severity: 'error', summary: 'Eksik alanlar var.', detail: element.$message, life: 3500 });
				});
			} else {
				try {
					const response = await this.crmService.getOtomasyonProjesiCreate(this.mainData);
					if (response) {
						if (response.hata == true) {
							this.$toast.add({severity:'error', summary: 'Kaydedilemedi', detail: response.hataAciklamasi, life: 5000});
						}else {
							this.$toast.add({severity:'success', summary: 'Bilgi', detail:'Başarıyla Kaydedildi', life: 3000});
							if (window.opener) {
								window.opener.window.postMessage({'tag': 'refresh'}, window.location.origin);
							}
							
							if (kapat) {
								setTimeout(() => {
									window.close();
								}, 1000);
							}
							else {
								setTimeout(() => {
									this.$router.push({ name: this.ENTITY_NAME, params: { id: response.olusturulanId } });
								}, 2000);
							}
						}
					}
				} catch (error) {
					this.$toast.add({severity:'error', summary: 'Eksik alanlar var.', detail: error.message, life: 3500});
				}
			}
		},
		OnDevreDisiBirak() {
			const postData = {
				logicalName: this.ENTITY_NAME,
				entiyId: this.entityId,
				stateCode: 1,
				statusCode: 2
			};

			this.devreDisiItem(postData);
		},
		handleScroll() {
			const el = document.getElementById('stickBar');

			if (window.scrollY > 50) {
				el.style.boxShadow = '0px 2px 1px rgba(0, 0, 0, 0.09), 0px 4px 2px rgba(0, 0, 0, 0.09), 0px 8px 4px rgba(0, 0, 0, 0.09), 0px 16px 8px rgba(0, 0, 0, 0.09), 0px 32px 16px rgba(0, 0, 0, 0.09)';
			}
			else {
				el.style.boxShadow = '';
			}
		},
	},
	watch: {
		profileData(val) {
			if (val) {
				if (val.moduller) {
					const filtered = val.moduller.filter(x => x.name == "Otomasyon Projesi");
					if (filtered.length == 0) {
						this.$router.replace({ name: 'accessdenied' });
					}
				}
			}
		}
	},
	validations() {
		return {
			mainData: {
				bm_name: {
					required : helpers.withMessage('Adı alanı dolu olmalıdır!', required),
				},
				bm_firmaId: {
					required : helpers.withMessage('Firma alanı dolu olmalıdır!', required),
				},
				bm_firmayetkilisiId: {
					required : helpers.withMessage('Firma Yetkilisi alanı dolu olmalıdır!', required),
				},
				bm_projetemsilcisiId: {
					required : helpers.withMessage('Proje Temsilcisi alanı dolu olmalıdır!', required),
				},
				bm_baslangictarihi: {
					required : helpers.withMessage('Başlangıç Tarihi alanı dolu olmalıdır!', required),
				},
				bm_projedurumu: {
					required : helpers.withMessage('Proje Durumu alanı dolu olmalıdır!', required),
				},
			},
		}
	}
}
</script>

<style lang="scss" scoped>
.stickBar {
	position: sticky;
    top: 6.2rem;
    z-index: 10;
}
</style>
